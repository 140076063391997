import React, { useEffect } from "react"
import Img from "gatsby-image/withIEPolyfill"

const Modal = ({
  title,
  image,
  content,
  setIsModal,
  slug,
  handleNext,
  handlePrevious,
}) => {
  const handleClose = () => {
    const modal = document.querySelector(".modal__overlay")
    modal.classList.remove("show")
    const bodyOverlay = document.querySelector(".body__overlay")
    bodyOverlay.style.removeProperty("opacity")
    bodyOverlay.style.removeProperty("visibility")
    setTimeout(() => {
      document.body.style.removeProperty("padding-right")
      document.body.classList.remove("lock")
      setIsModal(false)
    }, 500)
  }

  function preventClose(e) {
    e.stopPropagation()
  }

  function onKeyPressed(e) {
    if (e.keyCode === 39 && handleNext) {
      handleNext()
    } else if (e.keyCode === 37 && handlePrevious) {
      handlePrevious()
    }
  }
  useEffect(() => {
    document.addEventListener("keydown", onKeyPressed)
    return () => {
      document.removeEventListener("keydown", onKeyPressed)
    }
  })

  return (
    <>
      {/* eslint-disable-next-line */}
      <div
        className={`modal__overlay ${slug ? slug : ""}`}
        onClick={handleClose}
      >
        {/* eslint-disable-next-line */}
        <div
          className="modal__inner"
          onClick={preventClose}
          onKeyDown={preventClose}
        >
          {image && <Img fluid={image} alt={title} className="modal__image" />}
          <div className="modal__inner__box">
            <div className="modal__content">{content}</div>
          </div>

          <button
            onClick={handlePrevious}
            onKeyDown={handlePrevious}
            className={`cButton cButton__previous ${
              handlePrevious ? "" : "disable"
            }`}
            tabIndex={-1}
          >
            <span className="cButton__icon">
              <svg className="ar-left" viewBox="0 0 35.95 64.1">
                <polygon points="35.95 32.37 4.24 64.1 0 59.85 27.47 32.37 0 4.88 3.61 0 35.95 32.37" />
              </svg>
            </span>
          </button>
          <button
            onClick={handleNext}
            className={`cButton cButton__next ${handleNext ? "" : "disable"}`}
            tabIndex={-1}
          >
            <span className="cButton__icon">
              <svg className="ar-right" viewBox="0 0 35.95 64.1">
                <polygon points="35.95 32.37 4.24 64.1 0 59.85 27.47 32.37 0 4.88 3.61 0 35.95 32.37" />
              </svg>
            </span>
          </button>
          <button
            className="fixedButton"
            onClick={handleClose}
            onKeyDown={handleClose}
          >
            <span className="line line--top"></span>
            <span className="line line--bottom"></span>
          </button>
        </div>
      </div>
    </>
  )
}

export default Modal
