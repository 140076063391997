import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"

const Icon = styled.div`
  transition: 0.2s;
  margin: 5px auto;
  width: 45px;
`

const Vimeo = () => {
  const data = useStaticQuery(graphql`
    query {
      buttonVimeo: file(relativePath: { eq: "button_vimeo.png" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  const buttonVimeo = data.buttonVimeo.childImageSharp.fluid
  return (
    <Icon>
      <Img className="icon" fluid={buttonVimeo} alt="Vimeo" />
    </Icon>
  )
}
export default Vimeo
