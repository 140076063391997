import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import styles from "./Profile.module.scss"

const Profile = () => {
  const data = useStaticQuery(graphql`
    query {
      imageProfile: file(relativePath: { eq: "profile_main.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  const imageProfile = data.imageProfile.childImageSharp.fluid

  return (
    <div className={styles.root}>
      <div className="container-wide">
        <div className={styles.profile}>
          <Img className={styles.profile__image} fluid={imageProfile} alt="" />
          <div className={styles.profile__text}>
            <h3>Fumiro Sato</h3>
            <p>
              Fumiro is a director and VFX artist. As an editor, films he worked
              on were recognized or premiered at festivals such as the Venice
              Film Festival ("Monte" 2016, directed by Amir Naderi) and as an
              online editor, the Berlin Film Festival ("Ryuichi Sakamoto: async
              Live at the Park Avenue Armory" 2018). In fact, Naderi won the
              "Glory To The Filmmaker” award at the 73rd Venice Film Festival
              for this film.
            </p>
            <p>
              At post-production house McRay/Tokyo, he worked as an Autodesk
              Inferno edit suite assistant, at the Tokyo-based creative agency
              UltraSuperNew he planned and directed TV commercials, and he
              started his career as a 3D modeler for a Japanese Animated
              television series. Fumiro is based in Brooklyn and spends his
              weekends experimenting with 3D sculpting and web development
              projects.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
