import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import styles from "./Portfolio.module.scss"

import Fade from "react-reveal/Fade"

import Modal from "../Modal/Modal"
import Image from "../Image"
import IconYoutube from "../Icons/Youtube"
import IconVimeo from "../Icons/Vimeo"

const PortfolioItem = ({ id, name, image, delay }) => {
  const data = useStaticQuery(graphql`
    query {
      ModalImage1: file(relativePath: { eq: "portfolio_modal_monte.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ModalImage2: file(relativePath: { eq: "portfolio_modal_ainu1.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ModalImage2_2: file(relativePath: { eq: "portfolio_modal_ainu2.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ModalImage3: file(relativePath: { eq: "portfolio_modal_coda.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ModalImage4: file(relativePath: { eq: "portfolio_modal_async.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ModalImage5: file(relativePath: { eq: "portfolio_thumb_simpleman.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ModalImage6: file(relativePath: { eq: "portfolio_thumb_aleph.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ModalImage7: file(relativePath: { eq: "portfolio_modal_colin.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ModalImage8: file(
        relativePath: { eq: "portfolio_modal_blackclover.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ModalImage9: file(relativePath: { eq: "portfolio_modal_rituals.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ModalImage10: file(relativePath: { eq: "portfolio_modal_koshien.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ModalImage11: file(relativePath: { eq: "portfolio_modal_chicken.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ModalImage12: file(relativePath: { eq: "portfolio_modal_shell.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ModalImage13: file(relativePath: { eq: "portfolio_modal_dazn.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ModalImage14: file(relativePath: { eq: "portfolio_modal_clash.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ModalImage15: file(relativePath: { eq: "portfolio_modal_avatarin.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ModalImage16: file(relativePath: { eq: "portfolio_modal_maxell.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  const ModalImage1 = data.ModalImage1.childImageSharp.fluid
  const ModalImage2 = data.ModalImage2.childImageSharp.fluid
  const ModalImage2_2 = data.ModalImage2_2.childImageSharp.fluid
  const ModalImage3 = data.ModalImage3.childImageSharp.fluid
  const ModalImage4 = data.ModalImage4.childImageSharp.fluid
  const ModalImage5 = data.ModalImage5.childImageSharp.fluid
  const ModalImage6 = data.ModalImage6.childImageSharp.fluid
  const ModalImage7 = data.ModalImage7.childImageSharp.fluid
  const ModalImage8 = data.ModalImage8.childImageSharp.fluid
  const ModalImage9 = data.ModalImage9.childImageSharp.fluid
  const ModalImage10 = data.ModalImage10.childImageSharp.fluid
  const ModalImage11 = data.ModalImage11.childImageSharp.fluid
  const ModalImage12 = data.ModalImage12.childImageSharp.fluid
  const ModalImage13 = data.ModalImage13.childImageSharp.fluid
  const ModalImage14 = data.ModalImage14.childImageSharp.fluid
  const ModalImage15 = data.ModalImage15.childImageSharp.fluid
  const ModalImage16 = data.ModalImage16.childImageSharp.fluid

  const [isOpen, setIsOpen] = useState(false)
  const [isModalID, setIsModalID] = useState("")

  function handleOpen() {
    setIsOpen(true)
    setIsModalID(id)
    // Check body width & hide header
    const clientWidth = document.body.clientWidth
    // Add overflow:hidden & check scrollbar width & add padding-right to body & add modal element
    setTimeout(() => {
      document.body.classList.add("lock")
      const noScrollBarWidth = document.body.clientWidth
      const diff = noScrollBarWidth - clientWidth
      if (diff > 0) {
        document.body.style.paddingRight = diff + "px"
      }

      // Add fadein animation
      setTimeout(() => {
        const modal = document.querySelector(".md__content")
        modal.classList.add("show")
        const bodyOverlay = document.querySelector(".md__overlay")
        bodyOverlay.style.cssText = "opacity: 1; visibility: visible"
      }, 100)
    }, 100)
  }

  const ModalItem = () => {
    if (isModalID === 1) {
      return (
        <div className={styles.modal}>
          <div className={styles.modal__image}>
            <Img fluid={ModalImage1} alt="Monte" />
          </div>
          <div className={styles.modal__text}>
            <div className={styles.modal__description}>
              <h3>Monte</h3>
              <p>
                "Monte" is an Italian-French-US co-production movie directed by
                Iranian master director Amir Naderi.
              </p>
              <p>
                Fumiro spent about eight months together with Naderi, edited and
                edited music. The film was screened at the 73rd Venice Film
                Festival and won the "Glory to the film-maker award."
              </p>
              <p>
                The film was edited in Adobe Premiere, and the VFX part was done
                in Flame.
              </p>
            </div>
            <div className={styles.modal__trailer}>
              <a
                href="https://www.youtube.com/watch?v=XK_8lWEuUzM"
                target="_blank"
                rel="noreferrer"
                className={styles.modal__link}
              >
                <IconYoutube />
                <span className="caption">See trailer</span>
              </a>
            </div>
          </div>
        </div>
      )
    } else if (isModalID === 2) {
      return (
        <div className={styles.modal}>
          <div className={styles.modal__image}>
            <Img fluid={ModalImage2} alt="Ainu Mosir" />
          </div>
          <div className={styles.modal__text}>
            <div className={styles.modal__description}>
              <h3>Ainu Mosir </h3>
              <p>
                "Ainu Mosir" is a movie co-production between Japan and the US
                that story about Japan's indigenous people.
              </p>
              <p>
                Fumiro worked closely with the production team, planned and
                supervised VFX and post-treatment.
                <br />
                He used Maya to model and animate the VFX parts, use those
                elements for re-lighting and compositing. Also used Flame for
                conforming and online editing.
              </p>
              <p>
                The film won the "Special Jury Mention" award at the Tribeca
                Film Festival 2020.
              </p>
            </div>
            <div className={`modal__trailer ${styles.modal__trailer}`}>
              <Img fluid={ModalImage2_2} alt="Ainu Mosir" />
              <a
                href="https://www.youtube.com/watch?v=jcqgGaoQerc"
                target="_blank"
                rel="noreferrer"
                className={styles.modal__link}
              >
                <IconYoutube />
                <span className="caption">See trailer</span>
              </a>
            </div>
          </div>
        </div>
      )
    } else if (isModalID === 3) {
      return (
        <div className={styles.modal}>
          <div className={styles.modal__image}>
            <Img fluid={ModalImage3} alt="CODA" />
          </div>
          <div className={styles.modal__text}>
            <div className={styles.modal__description}>
              <h3>CODA</h3>
              <p>
                A documentary about Academy Award-winning music composer Ryuichi
                Sakamoto.
              </p>
              <p>
                Conformed and onlined using Flame.
                <br />
                Multiple shots were stabilized, the title works, and cleaned up
                in Flame. Fumiro led the post-production pipeline through to DCP
                delivery.
              </p>
              <p>This movie screened at the 74th Venice Film Festival.</p>
            </div>
            <div className={styles.modal__trailer}>
              <a
                href="https://www.youtube.com/watch?v=Fl-pKw5n0mI"
                target="_blank"
                rel="noreferrer"
                className={styles.modal__link}
              >
                <IconYoutube />
                <span className="caption">See trailer</span>
              </a>
            </div>
          </div>
        </div>
      )
    } else if (isModalID === 4) {
      return (
        <div className={styles.modal}>
          <div className={styles.modal__image}>
            <Img fluid={ModalImage4} alt="Async" />
          </div>
          <div className={styles.modal__text}>
            <div className={styles.modal__description}>
              <h3>Async</h3>
              <p>
                Async is a documentary movie of Academy Award-winning musician
                Ryuichi Sakamoto's concert at the New York Park Armory.
              </p>
              <p>
                It was challenging for the finishing process since the lighting
                conditions at the Park Armory were very demanding, and filming
                was done with minimal lighting.
                <br />
                Fumiro used Flame and was responsible for the online finishing
                touches.
              </p>
            </div>
            <div className={styles.modal__trailer}>
              <a
                href="https://www.youtube.com/watch?v=XO_7PMMua94"
                target="_blank"
                rel="noreferrer"
                className={styles.modal__link}
              >
                <IconYoutube />
                <span className="caption">See trailer</span>
              </a>
            </div>
          </div>
        </div>
      )
    } else if (isModalID === 5) {
      return (
        <div className={styles.modal}>
          <div
            className={`${styles.modal__image_narrow} ${styles.modal__image}`}
          >
            <Img fluid={ModalImage5} alt="I was a Simple Man" />
          </div>
          <div className={`${styles.modal__text_wide} ${styles.modal__text}`}>
            <div className={styles.modal__description}>
              <h3>I was a Simple Man</h3>
              <p>Directed by Tenzin Phuntsog {"&"} Joy Dietrich</p>
              <p>
                Fumiro supervised and composited VFX shots for the movie "I was
                a Simple Man.
                <br />
                The compositing was done in both Flame and Nuke. Fumiro was in
                charge of the VFX for the shots, which are an important part of
                the movie's storyline.
              </p>
              <p className={styles.modal__director}>
                director, Christopher Yogi
              </p>
            </div>
            <div className={styles.modal__trailer}>
              <a
                href="https://www.youtube.com/watch?v=a1ISpED1kQg"
                target="_blank"
                rel="noreferrer"
                className={styles.modal__link}
              >
                <IconYoutube />
                <span className="caption">See trailer</span>
              </a>
            </div>
          </div>
        </div>
      )
    } else if (isModalID === 6) {
      return (
        <div className={styles.modal}>
          <div
            className={`${styles.modal__image_narrow} ${styles.modal__image}`}
          >
            <Img fluid={ModalImage6} alt="Aleph" />
          </div>
          <div className={`${styles.modal__text_wide} ${styles.modal__text}`}>
            <div className={styles.modal__description}>
              <h3>Aleph</h3>
              <p>
                Fumiro was in charge of VFX shots, including green screen
                compositing.
              </p>
              <p>
                He was in charge of creating mysterious visual expressions that
                would be an important starting point for the story development.
                Flame was used to create the shots.
              </p>
              <p className={styles.modal__director}>
                director, Iva Radivojević
              </p>
            </div>
            <div className={styles.modal__trailer}>
              <a
                href="https://vimeo.com/532011893"
                target="_blank"
                rel="noreferrer"
                className={styles.modal__link}
              >
                <IconVimeo />
                <span className="caption">See trailer</span>
              </a>
            </div>
          </div>
        </div>
      )
    } else if (isModalID === 7) {
      return (
        <div className={styles.modal}>
          <div className={`${styles.modal__image}`}>
            <Img fluid={ModalImage7} alt="Colin Archdeacon's movie trailer" />
          </div>
          <div className={`${styles.modal__text}`}>
            <div className={styles.modal__description}>
              <h3>Colin Archdeacon's movie trailer</h3>
              <p>
                Fumiro designed all lighting that is done inside Flame while
                using the 3D model.
                <br />
                The rendering was done entirely on compositing, using
                physical-based rendering in Flame instead of CGI rendering.
                <br />
                Maya and Flame were used to complete the shot.
              </p>
              <p className={styles.modal__director}>
                director, Colin Archdeacon
              </p>
            </div>
          </div>
        </div>
      )
    } else if (isModalID === 8) {
      return (
        <div className={styles.modal}>
          <div className={`${styles.modal__image}`}>
            <Img fluid={ModalImage8} alt="Black Clover" />
          </div>
          <div className={`${styles.modal__text}`}>
            <div className={styles.modal__description}>
              <h3>Black Clover</h3>
              <p>
                Fumiro designed/directed motion graphics for the popular Anime,
                Black Clover promotional video.
                <br />
                He used Flame, After Effects, Premiere, and Maya to create the
                motion graphics.
                <br />
                The video has been viewed more than 800,000 times.
              </p>
            </div>
            <div className={styles.modal__trailer}>
              <a
                href="https://www.youtube.com/watch?v=kcpSP31USNw"
                target="_blank"
                rel="noreferrer"
                className={styles.modal__link}
              >
                <IconYoutube />
                <span className={styles.modal__caption}>See the video</span>
              </a>
            </div>
          </div>
        </div>
      )
    } else if (isModalID === 9) {
      return (
        <div className={styles.modal}>
          <div className={styles.modal__image}>
            <Img fluid={ModalImage9} alt="Rituals of Resistance" />
          </div>
          <div className={styles.modal__text}>
            <div className={styles.modal__description}>
              <h3>Rituals of Resistance</h3>
              <p>Directed by Tenzin Phuntsog {"&"} Joy Dietrich</p>
              <p>
                Fumiro was responsible for online, VFX, and CGI compositing. He
                also intermediated the color grading process.
                <br />
                Creating matte and roto tasks for color grading was also done by
                him.
              </p>
            </div>
            <div className={styles.modal__trailer}>
              <a
                href="https://vimeo.com/289734207"
                target="_blank"
                rel="noreferrer"
                className={styles.modal__link}
              >
                <IconVimeo />
                <span className="caption">See trailer</span>
              </a>
            </div>
          </div>
        </div>
      )
    } else if (isModalID === 10) {
      return (
        <div className={styles.modal}>
          <div
            className={`${styles.modal__image_narrow} ${styles.modal__image}`}
          >
            <Img fluid={ModalImage10} alt="Koshien Japans" />
          </div>
          <div className={`${styles.modal__text_wide} ${styles.modal__text}`}>
            <div className={styles.modal__description}>
              <h3>Koshien Japans</h3>
              <p>
                Fumiro was responsible for online and finishing work for this
                title. He supervised the online process, such as the title work
                and until DCP deliverable creation.
              </p>
              <p>
                This "Koshien" premiered at DOC NYC and broadcasted nationally
                on ESPN.
              </p>
            </div>
            <div className={styles.modal__trailer}>
              <a
                href="https://www.youtube.com/watch?v=g1I5PvXpvXE"
                target="_blank"
                rel="noreferrer"
                className={styles.modal__link}
              >
                <IconYoutube />
                <span className="caption">See trailer</span>
              </a>
            </div>
          </div>
        </div>
      )
    } else if (isModalID === 11) {
      return (
        <div className={styles.modal}>
          <div
            className={`${styles.modal__image_narrow} ${styles.modal__image}`}
          >
            <Img fluid={ModalImage11} alt="The Chicken" />
          </div>
          <div className={`${styles.modal__text_wide} ${styles.modal__text}`}>
            <div className={styles.modal__description}>
              <h3>The Chicken</h3>
              <p>
                This title was shot on 16mm film.
                <br />
                Fumiro completely removed the flicker in Flame, also matched the
                color grading, and delivered.
              </p>
            </div>
          </div>
        </div>
      )
    } else if (isModalID === 12) {
      return (
        <div className={styles.modal}>
          <div
            className={`${styles.modal__image_narrow} ${styles.modal__image}`}
          >
            <Img fluid={ModalImage12} alt="The Shell Collector" />
          </div>
          <div className={`${styles.modal__text_wide} ${styles.modal__text}`}>
            <div className={styles.modal__description}>
              <h3>The Shell Collector</h3>
              <p>
                16mm film title.
                <br />
                In collaboration with VFX houses B-9 service. Fumiro has done
                VFX of CGI creatures, building CG comp, and shot cleaning-up.
              </p>
            </div>
            <div className={styles.modal__trailer}>
              <a
                href="https://www.youtube.com/watch?v=CDqU4lcUjDM"
                target="_blank"
                rel="noreferrer"
                className={styles.modal__link}
              >
                <IconYoutube />
                <span className="caption">See trailer</span>
              </a>
            </div>
          </div>
        </div>
      )
    } else if (isModalID === 13) {
      return (
        <div className={styles.modal}>
          <div className={styles.modal__image}>
            <Img fluid={ModalImage13} alt='DAZN Spot - "Puppet story" series' />
          </div>
          <div className={styles.modal__text}>
            <div className={styles.modal__description}>
              <h3>DAZN Spot - "Puppet story" series</h3>
              <p>
                A commercial spot of puppet animation featuring former Spanish
                national soccer player Fernando Torres.
                <br />
                Fumiro was in charge of the planning, storyboarding, and
                direction. He also did the finishing work himself. The puppet
                animation was done in collaboration with animation studio
                MightyOak.
                <br />
                Directed and finished 3 series of the spot with MightyOak.
              </p>
            </div>
            <div className={styles.modal__trailer}>
              <a
                href="https://vimeo.com/300314922"
                target="_blank"
                rel="noreferrer"
                className={styles.modal__link}
              >
                <IconYoutube />
                <span className="caption">See trailer</span>
              </a>
            </div>
          </div>
        </div>
      )
    } else if (isModalID === 14) {
      return (
        <div className={styles.modal}>
          <div className={styles.modal__image}>
            <Img fluid={ModalImage14} alt="Supercell Clash of Clans" />
          </div>
          <div className={styles.modal__text}>
            <div className={styles.modal__description}>
              <h3>Supercell Clash of Clans</h3>
              <p>
                Fumiro directed Clash of Clans Japanese TV spots 7 series.
                <br />
                He worked on the planning, storyboarding, and set direction.
                <br />
                There were challenging Live-action composites of CG characters
                on those spots.
                <br />
                Works have done a collaboration with LA VFX studio PsyOp for
                this spot.
              </p>
            </div>
          </div>
        </div>
      )
    } else if (isModalID === 15) {
      return (
        <div className={styles.modal}>
          <div className={styles.modal__image}>
            <Img fluid={ModalImage15} alt="ANA avatorin" />
          </div>
          <div className={styles.modal__text}>
            <div className={styles.modal__description}>
              <h3>ANA avatarin</h3>
              <p>
                Fumiro was in charge of the online and color grading for ANA
                avatarin, web spot.
                <br />
                Flame was used to complete the entire process from conforming to
                delivery.
                <br />
                It performed remote grading between Tokyo and New York.
              </p>
            </div>
          </div>
        </div>
      )
    } else if (isModalID === 16) {
      return (
        <div className={styles.modal}>
          <div className={styles.modal__image}>
            <Img fluid={ModalImage16} alt="Maxell OZONEO" />
          </div>
          <div className={styles.modal__text}>
            <div className={styles.modal__description}>
              <h3>Maxell OZONEO</h3>
              <p>
                Fumiro was responsible for online, CGI direction, and color
                grading for Maxell TV spots.
                <br />
                The CGI was loaded directly into Flame via FBX, allowing
                fine-tuning of the graphic material during the client session.
                <br />
                All workflows were done in Aces and delivered in SDR.
              </p>
            </div>
            <div className={styles.modal__trailer}>
              <a
                href="https://www.youtube.com/watch?v=g7z687GMNaQ"
                target="_blank"
                rel="noreferrer"
                className={styles.modal__link}
              >
                <IconYoutube />
                <span className="caption">See trailer</span>
              </a>
            </div>
          </div>
        </div>
      )
    }
    return
  }

  return (
    <>
      <button className={styles.buttonItem} onClick={handleOpen}>
        <Fade
          bottom
          fraction={0.4}
          duration={400}
          delay={100 * delay}
          distance={"20px"}
        >
          <figure className="portfolio__item__image" role="figure">
            <Image image={image} name={name} />
          </figure>
        </Fade>
      </button>

      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isModalID={isModalID}
        setIsModalID={setIsModalID}
        contentID={"portfolio"}
      >
        <div className={`modal__wrapper`}>
          <ModalItem />
        </div>
      </Modal>
    </>
  )
}

export default PortfolioItem
