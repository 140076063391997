import React from "react"
import { createPortal } from "react-dom"
import ModalWrapper from "./ModalWrapper"
import styles from "./Modal.module.scss"

function preventClose(e) {
  e.stopPropagation()
}

export default function Modal({
  isOpen,
  setIsOpen,
  children,
  isModalID,
  setIsModalID,
  contentID,
}) {
  if (!isOpen) {
    return null
  }

  function handleClose() {
    const modal = document.querySelector(".md__content")
    modal.classList.remove("show")
    const bodyOverlay = document.querySelector(".md__overlay")
    bodyOverlay.style.removeProperty("opacity")
    bodyOverlay.style.removeProperty("visibility")
    setTimeout(() => {
      document.body.style.removeProperty("padding-right")
      document.body.classList.remove("lock")
      setIsOpen(false)
    }, 500)
  }

  function handleNext() {
    const modalItem = document.querySelector(".modal__wrapper")

    modalItem.style.opacity = "0"

    setTimeout(() => {
      setIsModalID(isModalID + 1)
      modalItem.style.opacity = "1"
    }, 500)
  }

  const handlePrevious = () => {
    const modalItem = document.querySelector(".modal__wrapper")

    modalItem.style.opacity = "0"

    setTimeout(() => {
      setIsModalID(isModalID - 1)
      modalItem.style.opacity = "1"
    }, 500)
  }

  return createPortal(
    <>
      <div className={`md__overlay ${styles.overlay}`}></div>
      {/* eslint-disable-next-line */}
      <div
        className={`md__content ${styles.content} ${contentID &&
          "modal-" + contentID}`}
        onClick={handleClose}
        onKeyDown={handleClose}
      >
        <div>
          {/* eslint-disable-next-line */}
          <div
            className={isModalID && `item-${isModalID}`}
            onClick={preventClose}
            onKeyDown={preventClose}
          >
            <button
              onClick={handlePrevious}
              aria-label="Previous Button"
              className={`button__previous ${styles.button__control} ${styles.button__previous}`}
              tabIndex={0}
            >
              <svg className={styles.ar} viewBox="0 0 50 50">
                <polyline points="14.49 3.33 35.94 24.79 14.06 46.67" />
              </svg>
            </button>

            <button
              onClick={handleNext}
              aria-label="Next Button"
              className={`button__next ${styles.button__control} ${styles.button__next}`}
              tabIndex={0}
            >
              <svg className={styles.ar} viewBox="0 0 50 50">
                <polyline points="14.49 3.33 35.94 24.79 14.06 46.67" />
              </svg>
            </button>

            <button
              className={styles.button__close}
              onClick={handleClose}
              aria-label="Close Button"
            ></button>
            <ModalWrapper
              contentID={contentID}
              isModalID={isModalID}
              handlePrevious={handlePrevious}
              handleNext={handleNext}
            >
              {children}
            </ModalWrapper>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  )
}
