import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import Modal from "../Modal/Modal"
import styles from "./Special.module.scss"

const SpecialItem = () => {
  const data = useStaticQuery(graphql`
    query {
      imageSpecialThumb: file(relativePath: { eq: "special_blackclover.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSpecial1: file(relativePath: { eq: "special_blackclover_1.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSpecial2: file(relativePath: { eq: "special_blackclover_2.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSpecial3: file(relativePath: { eq: "special_blackclover_3.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  const imageSpecialThumb = data.imageSpecialThumb.childImageSharp.fluid
  const imageSpecial1 = data.imageSpecial1.childImageSharp.fluid
  const imageSpecial2 = data.imageSpecial2.childImageSharp.fluid
  const imageSpecial3 = data.imageSpecial3.childImageSharp.fluid

  const [isOpen, setIsOpen] = useState(false)

  function handleOpen() {
    // Check body width & hide header
    const clientWidth = document.body.clientWidth
    // Add overflow:hidden & check scrollbar width & add padding-right to body & add modal element
    setTimeout(() => {
      document.body.classList.add("lock")
      const noScrollBarWidth = document.body.clientWidth
      const diff = noScrollBarWidth - clientWidth
      if (diff > 0) {
        document.body.style.paddingRight = diff + "px"
      }
      setIsOpen(true)
      // Add fadein animation
      setTimeout(() => {
        const modal = document.querySelector(".md__content")
        modal.classList.add("show")
        const bodyOverlay = document.querySelector(".md__overlay")
        bodyOverlay.style.cssText = "opacity: 1; visibility: visible"
      }, 100)
    }, 100)
  }

  return (
    <div className={`${styles.root} special`}>
      <button
        className={`${styles.special__item} special__item`}
        onClick={handleOpen}
      >
        <figure className={styles.special__item__image} role="figure">
          <div className={styles.overlay}></div>
          <Img fluid={imageSpecialThumb} alt="" />
          <figcaption>
            <span>
              Black Clover
              <br />1 Million views{" "}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
                <path d="M8.83,6.53A.84.84,0,0,0,8.81,5a.81.81,0,0,0,.3-.63.85.85,0,0,0-.84-.84H6.37a2.49,2.49,0,0,0,.28-1.4C6.6,1.57,6.15.62,5.45.57c-.53,0-.64.16-.64.7,0,0,0,.53-.11.86A2.51,2.51,0,0,1,3.59,3.57c-.93.66-1.25.77-1.26,1.21,0,.92,0,3,0,3.65s1.55,1,2.38,1H7.58c.46,0,1.09-.22,1.09-.84A.81.81,0,0,0,8.43,8a.84.84,0,0,0,.71-.83A.81.81,0,0,0,8.83,6.53Z" />
                <path d="M1.79,4.5h-1a.36.36,0,0,0-.36.36V8.54a.35.35,0,0,0,.36.35h1a.35.35,0,0,0,.35-.35V4.86A.36.36,0,0,0,1.79,4.5Z" />
              </svg>
              Motion Graphics
            </span>
          </figcaption>
        </figure>
      </button>

      <Modal isOpen={isOpen} setIsOpen={setIsOpen} contentID={"special"}>
        <div className={styles.modal}>
          <div>
            <div className={styles.modal__embed}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/kcpSP31USNw"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className={styles.modal__description}>
              <div
                className={`${styles.modal__container} ${styles.container1}`}
              >
                <div>
                  <h3 className={styles.modal__title}>
                    "Black Clover" Movie announcement PV
                  </h3>
                  <div className={styles.mb}>
                    Fumiro directed the motion graphics for the movie production
                    start announcement PV for the popular anime series "Black
                    Clover."
                  </div>
                  <div className={styles.mb}>
                    The video was released on YouTube with over 28,000 likes and
                    was featured in over 50 media.
                  </div>
                </div>

                <div className={styles.mb}>
                  <Img fluid={imageSpecial1} alt="" />
                </div>
              </div>

              <div className={styles.mb}>
                JUMP COMIC's popular manga "Black Clover" has been broadcast
                worldwide and recorded the top views in 87 countries on
                Crunchyroll.
              </div>
              <div className={styles.mb}>
                While this animation is long series and the story, the planning
                required the use of delicate materials and assets. Fumiro
                proposed camera angles and pre-viz in Maya from the early
                planning stage, designed key visuals, and smoothly managed the
                approval process and animation confirmation.
              </div>
              <div className={styles.mb}>
                <Img fluid={imageSpecial2} alt="" />
              </div>

              <div
                className={`${styles.modal__container} ${styles.container2}`}
              >
                <div className={styles.mb}>
                  Through this process, he imported camera motions and layouts
                  designed in Maya directly into Flame. And the animation that
                  was confirmed during the offline approval process was
                  connected directly to the online system for seamless delivery.
                </div>
                <div className={styles.mb}>
                  <Img fluid={imageSpecial3} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default SpecialItem
