import React, { useEffect } from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Social from "../components/Social"
import Fade from "react-reveal/Fade"
import Works from "../components/Works"
import Portfolio from "../components/Portfolio/Portfolio"
import Profile from "../components/Profile/Profile"
import Special from "../components/Special/Special"
import Recent from "../components/Recent/Recent"

const GalleryImage = ({ i, row }) => {
  return (
    <div className="gallery-item" key={i.toString()}>
      <Fade
        bottom
        fraction={0.8}
        duration={400}
        delay={100 * (i - (row - 1) * 4)}
        distance={"20px"}
      >
        <img
          className="gallery-item__image"
          src={require(`./../assets/images/portfolio_gallery_${i}.jpg`)}
          alt=""
        />
      </Fade>
    </div>
  )
}

const IndexPage = props => {
  let gallery1 = [],
    gallery2 = [],
    gallery3 = [],
    gallery4 = [],
    gallery5 = [],
    gallery6 = [],
    gallery7 = [],
    gallery8 = []
  for (let i = 1; i <= 4; ++i) {
    gallery1.push(<GalleryImage i={i} key={i} row="1" />)
  }
  for (let i = 5; i <= 8; ++i) {
    gallery2.push(<GalleryImage i={i} key={i} row="2" />)
  }
  for (let i = 9; i <= 12; ++i) {
    gallery3.push(<GalleryImage i={i} key={i} row="3" />)
  }
  for (let i = 13; i <= 16; ++i) {
    gallery4.push(<GalleryImage i={i} key={i} row="4" />)
  }
  for (let i = 17; i <= 20; ++i) {
    gallery5.push(<GalleryImage i={i} key={i} row="5" />)
  }
  for (let i = 21; i <= 24; ++i) {
    gallery6.push(<GalleryImage i={i} key={i} row="6" />)
  }
  for (let i = 25; i <= 28; ++i) {
    gallery7.push(<GalleryImage i={i} key={i} row="7" />)
  }
  for (let i = 29; i <= 32; ++i) {
    gallery8.push(<GalleryImage i={i} key={i} row="8" />)
  }

  const pageSlug = "home"

  // Parallax
  useEffect(() => {
    const special = document.querySelector(".special")
    const recent = document.querySelector(".recent")
    const parallax = document.querySelector(".parallax")
    const windowHeight = window.innerHeight

    document.addEventListener("scroll", () => {
      let yOffset = window.pageYOffset // Scroll Y
      const specialTopPos = special.getClientRects()[0].top + yOffset // Top of recent element
      const recentTopPos = recent.getClientRects()[0].top + yOffset // Top of recent element
      const startPos = specialTopPos - windowHeight * 0.32

      if (yOffset > startPos && yOffset < recentTopPos) {
        parallax.style.transform = `translateY(${-(yOffset - startPos) *
          0.55}px)`
      }
    })
  })

  return (
    <Layout location={props.location}>
      <SEO pageSlug={pageSlug} />

      <Works />

      <Profile />
      <Special />
      <div className="parallax">
        <Recent />

        <div className="container-wide sp-full">
          <Portfolio />
          <div className="gallery">
            {gallery1}
            {gallery2}
            {gallery3}
            {gallery4}
            {gallery5}
            {gallery6}
            {gallery7}
            {gallery8}
          </div>
        </div>

        <div className="container-wide">
          <div className="supplemental">
            <p>
              Using software: Flame/Smoke, Nuke, Adobe Premiere, Resolve, Maya,
              DVS Clipster, other Adobe tools, and Mudbox for sculpting.
            </p>
            <p>
              Demoreel:{" "}
              <a
                href="https://vimeo.com/245034910"
                target="_blank"
                rel="noopener noreferrer"
                className="hover"
              >
                Vimeo
              </a>
            </p>
            <p>
              Post-production App:{" "}
              <a
                href="https://postproduction.app/"
                target="_blank"
                rel="noopener noreferrer"
                className="hover"
              >
                Website
              </a>
            </p>
          </div>
          <div className="contact">
            <h3>Ask questions...</h3>
            <p>fumiro12&#64;gmail.com</p>
          </div>

          <div className="social">
            <Social />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
