import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"

const Icon = styled.div`
  transition: 0.2s;
  margin: 5px auto;
  width: 45px;
`

const Youtube = () => {
  const data = useStaticQuery(graphql`
    query {
      buttonYoutube: file(relativePath: { eq: "button_youtube.png" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  const buttonYoutube = data.buttonYoutube.childImageSharp.fluid
  return (
    <Icon>
      <Img className="icon" fluid={buttonYoutube} alt="Youtube" />
    </Icon>
  )
}
export default Youtube
