import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import styles from "./Recent.module.scss"

const Recent = () => {
  const data = useStaticQuery(graphql`
    query {
      imageFeatured1: file(relativePath: { eq: "profile_featured_ainu.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFeatured2: file(relativePath: { eq: "profile_featured_async.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFeatured3: file(
        relativePath: { eq: "profile_featured_sundance.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFeatured4: file(
        relativePath: { eq: "profile_featured_docnyc.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  const imageFeatured1 = data.imageFeatured1.childImageSharp.fluid
  const imageFeatured2 = data.imageFeatured2.childImageSharp.fluid
  const imageFeatured3 = data.imageFeatured3.childImageSharp.fluid
  const imageFeatured4 = data.imageFeatured4.childImageSharp.fluid

  return (
    <div className={styles.root}>
      <div className={`${styles.recent} recent`}>
        <div className="container-wide">
          <h3 className={styles.recent__head}>Recent works</h3>

          <div className={styles.featured}>
            <div className={styles.featured__item}>
              <div className={styles.featured__image}>
                <a
                  href="https://www.docnyc.net/awards/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Img fluid={imageFeatured4} alt="" />
                </a>
              </div>
              <div className={styles.featured__text}>
                <p>
                  I worked as finishing editor for the film "Nude at Heart." It
                  won the Grand Jury Prize in the Kaleidoscope at the largest
                  documentary film festival in North America.
                </p>
              </div>
            </div>

            <div className={styles.featured__item}>
              <div className={styles.featured__image}>
                <a
                  href="https://filmmakermagazine.com/111038-my-collaborators-are-geniuses-editor-christopher-makoto-yogi-on-i-was-a-simple-man/#.YBcrT3dKg8N"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Img fluid={imageFeatured3} alt="" />
                </a>
              </div>
              <div className={styles.featured__text}>
                <p>
                  “I was a Simple Man,” directed by Christopher Yogi, screened
                  at Sundance Film Festival 2021. Fumiro did all the Visual
                  Effects treatment of the film in Nuke and Flame. Christopher
                  mentioned Fumiro’s name at the director’s{" "}
                  <a
                    href="https://filmmakermagazine.com/111038-my-collaborators-are-geniuses-editor-christopher-makoto-yogi-on-i-was-a-simple-man/#.YBcrT3dKg8N"
                    target="_blank"
                    rel="noreferrer"
                    className="hover"
                  >
                    interview
                  </a>
                  .
                </p>
              </div>
            </div>

            <div className={styles.featured__item}>
              <div className={styles.featured__image}>
                <a
                  href="https://www.netflix.com/browse"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Img fluid={imageFeatured1} alt="" />
                </a>
              </div>
              <div className={styles.featured__text}>
                <p>
                  Fumiro VFX supervised Tribeca Film Festival 2020 International
                  Narrative Competition Special Jury Mention winning movie “Ainu
                  Mosir” is now playing on{" "}
                  <a
                    href="https://www.netflix.com/browse"
                    target="_blank"
                    rel="noreferrer"
                    className="hover"
                  >
                    Netflix
                  </a>
                  .
                </p>
              </div>
            </div>

            <div className={styles.featured__item}>
              <div className={styles.featured__image}>
                <a
                  href="https://mubi.com/films/ryuichi-sakamoto-async-at-the-park-avenue-armory"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Img fluid={imageFeatured2} alt="" />
                </a>
              </div>
              <div className={styles.featured__text}>
                <p>
                  VFX/Finishing movie “Ryuichi Sakamoto: async” is now playing
                  on{" "}
                  <a
                    href="https://mubi.com/films/ryuichi-sakamoto-async-at-the-park-avenue-armory"
                    target="_blank"
                    rel="noreferrer"
                    className="hover"
                  >
                    MUBI
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>

          <div className={styles.others}>
            <div className={styles.others__list}>
              ・77th Venice Film Festival: Venice Classic "Wheels of Fate" /
              Colorist, Online Editor
            </div>
            <div className={styles.others__list}>
              ・IDFA Amsterdam - "Odoriko" directed by Yoichi Okutani / Online
              Editor
            </div>
            <div className={styles.others__list}>
              ・NHK Japan “Alvin Ailey - Chaya Masazumi” documentary / Colorist
              / Finishing
            </div>
            <div className={styles.others__list}>
              ・ESPN "Koshien japan's field of dreams" directed by Ema Yamazaki
              / Online, Finishing
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Recent
