import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import PortfolioItem from "./PortfolioItem"
import styles from "./Portfolio.module.scss"

const Portfolio = () => {
  const data = useStaticQuery(graphql`
    query {
      ImageMonte: file(relativePath: { eq: "portfolio_thumb_monte.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ImageAinu: file(relativePath: { eq: "portfolio_thumb_ainu.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ImageCoda: file(relativePath: { eq: "portfolio_thumb_coda.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ImageAsync: file(relativePath: { eq: "portfolio_thumb_async.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ImageRituals: file(relativePath: { eq: "portfolio_thumb_rituals.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ImageKoshien: file(relativePath: { eq: "portfolio_thumb_koshien.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ImageChicken: file(relativePath: { eq: "portfolio_thumb_chicken.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ImageShell: file(relativePath: { eq: "portfolio_thumb_shell.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ImageDazn: file(relativePath: { eq: "portfolio_thumb_dazn.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ImageClash: file(relativePath: { eq: "portfolio_thumb_clash.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ImageAvatarin: file(
        relativePath: { eq: "portfolio_thumb_avatarin.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ImageMaxell: file(relativePath: { eq: "portfolio_thumb_maxell.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ImageSimpleman: file(
        relativePath: { eq: "portfolio_thumb_simpleman.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ImageAleph: file(relativePath: { eq: "portfolio_thumb_aleph.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ImageColin: file(relativePath: { eq: "portfolio_thumb_colin.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ImageBlackclover: file(
        relativePath: { eq: "portfolio_thumb_blackclover.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  const ImageMonte = data.ImageMonte.childImageSharp.fluid
  const ImageAinu = data.ImageAinu.childImageSharp.fluid
  const ImageCoda = data.ImageCoda.childImageSharp.fluid
  const ImageAsync = data.ImageAsync.childImageSharp.fluid
  const ImageRituals = data.ImageRituals.childImageSharp.fluid
  const ImageKoshien = data.ImageKoshien.childImageSharp.fluid
  const ImageChicken = data.ImageChicken.childImageSharp.fluid
  const ImageShell = data.ImageShell.childImageSharp.fluid
  const ImageDazn = data.ImageDazn.childImageSharp.fluid
  const ImageClash = data.ImageClash.childImageSharp.fluid
  const ImageAvatarin = data.ImageAvatarin.childImageSharp.fluid
  const ImageMaxell = data.ImageMaxell.childImageSharp.fluid
  const ImageSimpleman = data.ImageSimpleman.childImageSharp.fluid
  const ImageAleph = data.ImageAleph.childImageSharp.fluid
  const ImageColin = data.ImageColin.childImageSharp.fluid
  const ImageBlackclover = data.ImageBlackclover.childImageSharp.fluid

  return (
    <div className={`portfolio ${styles.portfolio}`}>
      <div className={styles.buttonItems}>
        <PortfolioItem id={1} delay={1} name="Monte" image={ImageMonte} />
        <PortfolioItem id={2} delay={2} name="Ainu Mosir" image={ImageAinu} />
        <PortfolioItem id={3} delay={3} name="Coda" image={ImageCoda} />
        <PortfolioItem id={4} delay={4} name="Async" image={ImageAsync} />
        <PortfolioItem
          id={5}
          delay={1}
          name="I was a simple man"
          image={ImageSimpleman}
        />
        <PortfolioItem id={6} delay={2} name="Aleph" image={ImageAleph} />
        <PortfolioItem
          id={7}
          delay={3}
          name="Movie trailer"
          image={ImageColin}
        />
        <PortfolioItem
          id={8}
          delay={4}
          name="Black clover"
          image={ImageBlackclover}
        />
        <PortfolioItem
          id={9}
          delay={1}
          name="Rituals Of Resistance"
          image={ImageRituals}
        />
        <PortfolioItem
          id={10}
          delay={2}
          name="Koshien Japans"
          image={ImageKoshien}
        />
        <PortfolioItem
          id={11}
          delay={3}
          name="The chicken"
          image={ImageChicken}
        />
        <PortfolioItem
          id={12}
          delay={4}
          name="The Shell Collector"
          image={ImageShell}
        />
        <PortfolioItem
          id={13}
          delay={1}
          name='DAZN Spots - "Puppet story"'
          image={ImageDazn}
        />
        <PortfolioItem
          id={14}
          delay={2}
          name="Supercell Clash of Clans"
          image={ImageClash}
        />
        <PortfolioItem id={15} delay={3} name="" image={ImageAvatarin} />
        <PortfolioItem id={16} delay={4} name="Maxell" image={ImageMaxell} />
      </div>
    </div>
  )
}

export default Portfolio
