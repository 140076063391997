import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import Fade from "react-reveal/Fade"

import Modal from "./Modal"
import Image from "./Image"

const WorksItem = ({
  worksID,
  worksName,
  worksDelay,
  worksImage,
  worksSlug,
  isModalID,
  setIsModalID,
}) => {
  const data = useStaticQuery(graphql`
    query {
      buttonYoutube: file(relativePath: { eq: "button_youtube.png" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      buttonVimeo: file(relativePath: { eq: "button_vimeo.png" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageModalCommercial: file(
        relativePath: { eq: "works_modal_commercial.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageModalGrading: file(relativePath: { eq: "works_modal_grading.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageModalPrinter: file(relativePath: { eq: "works_modal_printer.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  const buttonYoutube = data.buttonYoutube.childImageSharp.fluid
  const buttonVimeo = data.buttonVimeo.childImageSharp.fluid
  const imageModalCommercial = data.imageModalCommercial.childImageSharp.fluid
  const imageModalGrading = data.imageModalGrading.childImageSharp.fluid
  const imageModalPrinter = data.imageModalPrinter.childImageSharp.fluid

  const [isModal, setIsModal] = useState(false)

  function handleOpen() {
    setIsModalID(worksID)
    // Check body width & hide header
    const clientWidth = document.body.clientWidth
    // Add overflow:hidden & check scrollbar width & add padding-right to body & add modal element
    setTimeout(() => {
      document.body.classList.add("lock")
      const noScrollBarWidth = document.body.clientWidth
      const diff = noScrollBarWidth - clientWidth
      if (diff > 0) {
        document.body.style.paddingRight = diff + "px"
      }
      setIsModal(true)
      // Add fadein animation
      setTimeout(() => {
        const modal = document.querySelector(".modal__overlay")
        modal.classList.add("show")
        const bodyOverlay = document.querySelector(".body__overlay")
        bodyOverlay.style.cssText = "opacity: 1; visibility: visible"
      }, 100)
    }, 100)
  }

  const handleNext = () => {
    const modal = document.querySelector(".modal__overlay")
    modal.classList.remove("show")
    setTimeout(() => {
      setIsModalID(isModalID + 1)
      setTimeout(() => {
        const modal = document.querySelector(".modal__overlay")
        modal.classList.add("show")
      }, 50)
    }, 500)
  }

  const handlePrevious = () => {
    const modal = document.querySelector(".modal__overlay")
    modal.classList.remove("show")
    setTimeout(() => {
      setIsModalID(isModalID - 1)
      setTimeout(() => {
        const modal = document.querySelector(".modal__overlay")
        modal.classList.add("show")
      }, 50)
    }, 500)
  }

  return (
    <>
      <button
        className={`works__item works__item--${worksSlug}`}
        onClick={handleOpen}
      >
        <figure className="works__item__image" role="figure">
          <Fade bottom duration={400} delay={worksDelay} distance={"20px"}>
            <div>
              <Image image={worksImage} name={worksSlug} />
              <figcaption className="works__item__caption">
                {worksName}
              </figcaption>
            </div>
          </Fade>
        </figure>
      </button>

      {isModal && isModalID === 1 && (
        <Modal
          slug="commercial"
          handleNext={handleNext}
          setIsModal={setIsModal}
          content={
            <>
              <Img
                className="modal__bg"
                fluid={imageModalCommercial}
                alt="Commercial"
              />
              <div className="modal__text">
                <div className="modal__text__inner">
                  <div className="modal__text__description">
                    <h3>Commercial / Finishing</h3>
                    <p>
                      As a Flame Artist, Fumiro works on feature films,
                      commercial finishing, and shot-by-shot VFX. In addition to
                      3D modeling, Fumiro specializes in complex shot
                      re-lighting and compositing using 3D elements and camera
                      tracking. In recent years, He's kept busy with finishing
                      work for feature films.
                    </p>
                    <p>
                      Fumiro also designs workflows based on ACES that take
                      Color Grading and VFX into consideration.
                    </p>
                  </div>
                  <a
                    href="https://www.youtube.com/watch?v=OAGrdrvcVR8"
                    target="_blank"
                    rel="noreferrer"
                    className="modal__text__button"
                  >
                    <span className="caption">See YouTube for examples</span>
                    <Img className="icon" fluid={buttonYoutube} alt="" />
                  </a>
                </div>
              </div>
            </>
          }
        />
      )}
      {isModal && isModalID === 2 && (
        <Modal
          slug="grading"
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          setIsModal={setIsModal}
          content={
            <>
              <Img
                className="modal__bg"
                fluid={imageModalGrading}
                alt="Color Grading"
              />
              <div className="modal__text">
                <div className="modal__text__inner">
                  <a
                    href="https://vimeo.com/441817097"
                    target="_blank"
                    rel="noreferrer"
                    className="modal__text__button"
                  >
                    <div className="caption">
                      See example
                      <br />
                      "Elevator Pitch" Directed by Martyna Starosta
                    </div>
                    <Img className="icon" fluid={buttonVimeo} alt="" />
                  </a>
                  <div className="modal__text__description">
                    <h3>Color Grading</h3>
                    <p>
                      In recent years Fumiro has expanded from color grading in
                      a commercial context to doing it for short and feature
                      films. He makes the most of the color grading capabilities
                      available in Autodesk Flame (greatly extended since the
                      2017 version was released), allowing for seamless color
                      grading between small VFX treatments and finishing.
                    </p>
                    <p>
                      Most recently, Fumiro color-graded the short film, "Wheels
                      of Fate," directed by Ema Ryan Yamazaki, that was screened
                      at the Venice Film Festival and the Tokyo International
                      Film Festival.
                    </p>
                  </div>
                </div>
              </div>
            </>
          }
        />
      )}
      {isModal && isModalID === 3 && (
        <Modal
          slug="printer"
          handlePrevious={handlePrevious}
          setIsModal={setIsModal}
          content={
            <>
              <Img
                className="modal__bg"
                fluid={imageModalPrinter}
                alt="Ambitious Projects"
              />
              <div className="modal__text">
                <div className="modal__text__inner">
                  <a
                    href="https://www.instagram.com/printingduh/"
                    target="_blank"
                    rel="noreferrer"
                    className="modal__text__button"
                  >
                    <svg
                      id="instagram"
                      data-name="instagram"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 56.69 56.69"
                    >
                      <path d="M28.35,5.32c7.49,0,8.38,0,11.34.17a15.61,15.61,0,0,1,5.22,1,8.71,8.71,0,0,1,3.23,2.11,8.69,8.69,0,0,1,2.1,3.22,15.61,15.61,0,0,1,1,5.22c.14,3,.17,3.85.17,11.35s0,8.38-.17,11.34a15.61,15.61,0,0,1-1,5.22,9.29,9.29,0,0,1-5.33,5.33,15.61,15.61,0,0,1-5.22,1c-3,.14-3.84.17-11.34.17S20,51.34,17,51.2a15.61,15.61,0,0,1-5.22-1,8.69,8.69,0,0,1-3.22-2.1,8.56,8.56,0,0,1-2.1-3.23,15.29,15.29,0,0,1-1-5.22c-.14-3-.17-3.85-.17-11.34S5.35,20,5.49,17a15.29,15.29,0,0,1,1-5.22,8.45,8.45,0,0,1,2.1-3.22,8.59,8.59,0,0,1,3.22-2.11,15.61,15.61,0,0,1,5.22-1c3-.14,3.85-.17,11.35-.17m0-5.06c-7.63,0-8.59,0-11.58.17A20.58,20.58,0,0,0,10,1.74,13.85,13.85,0,0,0,5,5a13.85,13.85,0,0,0-3.24,5A20.58,20.58,0,0,0,.43,16.77c-.13,3-.17,3.95-.17,11.58s0,8.58.17,11.57a20.58,20.58,0,0,0,1.31,6.82,13.76,13.76,0,0,0,3.24,5A13.59,13.59,0,0,0,10,55a20.58,20.58,0,0,0,6.82,1.31c3,.14,3.95.17,11.58.17s8.58,0,11.57-.17A20.58,20.58,0,0,0,46.74,55,14.39,14.39,0,0,0,55,46.74a20.58,20.58,0,0,0,1.31-6.82c.14-3,.17-4,.17-11.57s0-8.59-.17-11.58A20.58,20.58,0,0,0,55,10a13.59,13.59,0,0,0-3.24-5,13.76,13.76,0,0,0-5-3.24A20.58,20.58,0,0,0,39.92.43c-3-.13-4-.17-11.57-.17ZM28.4,14A14.43,14.43,0,1,0,42.83,28.4,14.43,14.43,0,0,0,28.4,14Zm0,23.8a9.37,9.37,0,1,1,9.37-9.37,9.37,9.37,0,0,1-9.37,9.37ZM46.62,13.3A3.34,3.34,0,1,1,43.28,10a3.34,3.34,0,0,1,3.34,3.34Zm0,0" />
                    </svg>
                    <span className="caption">3D Sculpting works</span>
                  </a>

                  <div className="modal__text__description">
                    <h3>Ambitious Projects</h3>
                    <p>
                      Fumiro has a passion for new technologies. Recently, he
                      has been experimenting with 3D printing, developing
                      sculptural work (such as the photo shown here).
                    </p>
                    <p>
                      In addition, he’s recently developed a browser-based
                      application in collaboration with the UI/UX Designer{" "}
                      <a
                        href="https://ayaos.work/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Ayana Osawa
                      </a>{" "}
                      called the “
                      <a
                        href="https://postproduction.app/netflix-deliverable-naming"
                        target="_blank"
                        rel="noreferrer"
                        className="modal__text__link"
                      >
                        Netflix Naming Convention
                      </a>
                      ” app, which allows post-production workers to generate
                      file names for deliverables automatically. It has been
                      released free of charge and has gained many users and
                      attention in the post-production community.
                    </p>
                  </div>
                </div>
              </div>
            </>
          }
        />
      )}
    </>
  )
}

export default WorksItem
