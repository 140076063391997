import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import styled from "styled-components"

const SocialWrapper = styled.div`
  .socialLink {
    display: inline-block;
    padding-right: 2rem;
    &:last-child {
      padding-right: 0;
    }
    svg {
      width: 2.2rem;
      height: 2.2rem;
    }
    #linkedin-svg {
      margin-bottom: 2px;
    }
    &:hover {
      @media (min-width: 750px) {
        transform: translateY(-3px);
      }
    }
  }
`

const Social = () => {
  const data = useStaticQuery(graphql`
    query SocialQuery {
      site {
        siteMetadata {
          social {
            instagram
            linkedin
          }
        }
      }
    }
  `)

  return (
    <SocialWrapper>
      <a
        className="socialLink"
        target="_blank"
        rel="noopener noreferrer"
        href={`https://instagram.com/${data.site.siteMetadata.social.instagram}`}
      >
        <svg
          id="instagram"
          data-name="instagram"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 56.69 56.69"
        >
          <path
            d="M28.35,5.32c7.49,0,8.38,0,11.34.17a15.61,15.61,0,0,1,5.22,1,8.71,8.71,0,0,1,3.23,2.11,8.69,8.69,0,0,1,2.1,3.22,15.61,15.61,0,0,1,1,5.22c.14,3,.17,3.85.17,11.35s0,8.38-.17,11.34a15.61,15.61,0,0,1-1,5.22,9.29,9.29,0,0,1-5.33,5.33,15.61,15.61,0,0,1-5.22,1c-3,.14-3.84.17-11.34.17S20,51.34,17,51.2a15.61,15.61,0,0,1-5.22-1,8.69,8.69,0,0,1-3.22-2.1,8.56,8.56,0,0,1-2.1-3.23,15.29,15.29,0,0,1-1-5.22c-.14-3-.17-3.85-.17-11.34S5.35,20,5.49,17a15.29,15.29,0,0,1,1-5.22,8.45,8.45,0,0,1,2.1-3.22,8.59,8.59,0,0,1,3.22-2.11,15.61,15.61,0,0,1,5.22-1c3-.14,3.85-.17,11.35-.17m0-5.06c-7.63,0-8.59,0-11.58.17A20.58,20.58,0,0,0,10,1.74,13.85,13.85,0,0,0,5,5a13.85,13.85,0,0,0-3.24,5A20.58,20.58,0,0,0,.43,16.77c-.13,3-.17,3.95-.17,11.58s0,8.58.17,11.57a20.58,20.58,0,0,0,1.31,6.82,13.76,13.76,0,0,0,3.24,5A13.59,13.59,0,0,0,10,55a20.58,20.58,0,0,0,6.82,1.31c3,.14,3.95.17,11.58.17s8.58,0,11.57-.17A20.58,20.58,0,0,0,46.74,55,14.39,14.39,0,0,0,55,46.74a20.58,20.58,0,0,0,1.31-6.82c.14-3,.17-4,.17-11.57s0-8.59-.17-11.58A20.58,20.58,0,0,0,55,10a13.59,13.59,0,0,0-3.24-5,13.76,13.76,0,0,0-5-3.24A20.58,20.58,0,0,0,39.92.43c-3-.13-4-.17-11.57-.17ZM28.4,14A14.43,14.43,0,1,0,42.83,28.4,14.43,14.43,0,0,0,28.4,14Zm0,23.8a9.37,9.37,0,1,1,9.37-9.37,9.37,9.37,0,0,1-9.37,9.37ZM46.62,13.3A3.34,3.34,0,1,1,43.28,10a3.34,3.34,0,0,1,3.34,3.34Zm0,0"
          />
        </svg>
      </a>
      <a
        className="socialLink"
        target="_blank"
        rel="noopener noreferrer"
        href={`https://www.linkedin.com/in/${data.site.siteMetadata.social.linkedin}`}
      >
        <svg
          id="linkedin-svg"
          data-name="linkedin-svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 56.69 56.69"
        >
          <path
            d="M1.69,18.28H12.63V56.42H1.69V18.28ZM6.88,13.5C-1.85,13.8-1.8-.06,7,.27,15.62,0,15.69,13.79,6.88,13.5ZM56.42,56.42H44V36.68C44,31.51,42.07,28,37.8,28c-4.48.21-6.45,4-6.19,7.79V56.42H19.31s.16-35,0-38.14h12.3v6c.73-2.62,4.65-6.35,10.92-6.35,7.78,0,13.89,5.49,13.89,17.3V56.42Zm0,0"
          />
        </svg>
      </a>
    </SocialWrapper>
  )
}

export default Social
