import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import WorksItem from "./WorksItem"

const Works = () => {
  const [isModalID, setIsModalID] = useState("")

  const data = useStaticQuery(graphql`
    query {
      imageCommercial: file(
        relativePath: { eq: "works_button_commercial.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageGrading: file(relativePath: { eq: "works_button_grading.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imagePrinter: file(relativePath: { eq: "works_button_printer.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  const imageCommercial = data.imageCommercial.childImageSharp.fluid
  const imageGrading = data.imageGrading.childImageSharp.fluid
  const imagePrinter = data.imagePrinter.childImageSharp.fluid

  const num = []
  for (let i = 0; i < 3; ++i) {
    num.push(i * 100 + 300)
  }
  return (
    <>
      <div className="works__items">
        <WorksItem
          worksID={1}
          isModalID={isModalID}
          setIsModalID={setIsModalID}
          worksName={
            <>
              Commercial<span> / </span>Finishing
            </>
          }
          worksImage={imageCommercial}
          worksDelay={num[0]}
          worksSlug="commercial"
        />
        <WorksItem
          worksID={2}
          isModalID={isModalID}
          setIsModalID={setIsModalID}
          worksName="Color Grading"
          worksImage={imageGrading}
          worksDelay={num[1]}
          worksSlug="grading"
        />
        <WorksItem
          worksID={3}
          isModalID={isModalID}
          setIsModalID={setIsModalID}
          worksName="Ambitious Projects"
          worksImage={imagePrinter}
          worksDelay={num[2]}
          worksSlug="printer"
        />
      </div>
    </>
  )
}

export default Works
