import React, { useEffect } from "react"

// Component for keypress function
export default function ModalWrapper({
  isModalID,
  contentID,
  children,
  handleNext,
  handlePrevious,
}) {
  const onKeyPressed = e => {
    if (e.keyCode === 39 && isModalID < 16) {
      // Not perfect but for temporary
      handleNext()
    } else if (e.keyCode === 37 && isModalID > 1) {
      handlePrevious()
    }
  }

  useEffect(() => {
    if (contentID === "portfolio") {
      document.addEventListener("keydown", onKeyPressed)
      return () => {
        document.removeEventListener("keydown", onKeyPressed)
      }
    }
  })

  return <div>{children}</div>
}
